


















import {Component, Prop, Vue} from 'vue-property-decorator'
import {BetDto} from "@/types";

@Component({components: {}})
export default class ChessBetLeft extends Vue {

  @Prop({ required: true}) leftBettersTurn!: boolean;
  @Prop({ required: true}) bet!: BetDto;
  @Prop({ required: true}) playerA!: string;
  @Prop({ required: true}) playerB!: string;
  @Prop({ required: true}) amInext!: boolean;

  choose(){
    this.$emit('choose', this.bet);
  }

};


