import {BetDto, ChessBetDto, CreatedResponse, GameDto} from "@/types";

const baseURL = `/api`

export function getBetsLeft(gameId: string, betterId: string): Promise<BetDto[]> {
    return fetch(`${baseURL}/left/${gameId}/${betterId}`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'Cache-Control': 'no-cache' }
    }).then(response => handleError<BetDto[]>(response));
}

export function getAllPossibleGames(gameId: string, betterId: string): Promise<GameDto[]> {
    return fetch(`${baseURL}/possible/${gameId}/${betterId}`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'Cache-Control': 'no-cache' }
    }).then(response => handleError<GameDto[]>(response));
}

export function getAll(gameId: string, betterId: string): Promise<ChessBetDto> {
    return fetch(`${baseURL}/get/${gameId}/${betterId}`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'Cache-Control': 'no-cache' }
    }).then(response => handleError<ChessBetDto>(response));
}

export function getNextBetter(gameId: string, betterId: string): Promise<number> {
    return fetch(`${baseURL}/next/${gameId}/${betterId}`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'Cache-Control': 'no-cache' }
    }).then(response => handleError<number>(response));
}

export function createBet(name: string, betterA: string, betterB: string, playerA: string, playerB: string, numberOfGames: number, firstWhite: string, apikey: string): Promise<CreatedResponse> {
    return fetch(`${baseURL}/create/`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-api-key': apikey
        },
        body: JSON.stringify({ name: name, betterA: betterA, betterB:betterB, playerA:playerA, playerB:playerB,numberOfGames:numberOfGames,firstWhite:firstWhite })
    }).then(response => handleError<CreatedResponse>(response));
}

export function placeBet(gameId: string, betterId: string, betDto: BetDto): Promise<void> {
    return fetch(`${baseURL}/bet/${gameId}/${betterId}/`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ playerA: betDto.playerA, playerB:betDto.playerB})
    }).then(response => {
        if ([200, 204].includes(response.status)) return Promise.resolve();
        return handleError<void>(response)
    });
}

function handleError<T>(response: Response): Promise<T> {
    if (response.ok) return response.json();
    return response.text().then(t => {
        // console.warn(response.status, t);
        if (t) {
            try {
                let msg = JSON.parse(t);
                if(msg.type){
                    return Promise.reject(msg);
                }
                return Promise.reject(msg.errorMessage || t);
            } catch(e) {
                return Promise.reject(t);
            }
        }
        return Promise.reject(response.status + ' - ' + response.statusText);
    });
}

