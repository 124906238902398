





























import {Component, Prop, Vue} from 'vue-property-decorator'
import {BetterDto, FinalBetDto} from "@/types";

@Component({components: {}})
export default class ChessBetLeft extends Vue {

  @Prop({required: true}) better!: BetterDto;
  @Prop({required: true}) bets!: FinalBetDto[];
  @Prop({required: true}) playerA!: string;
  @Prop({required: true}) playerB!: string;


};


