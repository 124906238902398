


































































































































































import {Component, Vue, Watch} from 'vue-property-decorator'
import {getAll, getAllPossibleGames} from "@/api";
import {ActiveResult, BetDto, ChessBetDto, Combined, FinalBetDto, GameDto} from "@/types";
import LiveBets from "@/views/LiveBets.vue";


@Component({components: {LiveBets}})
export default class Chess extends Vue {

  WHITE_WIN = "WHITE"
  BLACK_WIN = "BLACK"
  DRAW = "DRAW"

  chessBet: ChessBetDto | null = null;
  possibleGames: GameDto[] = [];
  combined: Combined[] = [];

  nextBetterId: number | null = null;

  error: string | null = null;

  intervalRef: number | null = null;

  betting: boolean = false;

  stillValidBets: FinalBetDto[] = []
  betsPast: FinalBetDto[] = []
  betsNeverReach: FinalBetDto[] = []

  days = ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"]
  monts = ["januar", "februar", "mars", "april", "mai", "juni", "juli", "august", "september", "oktober", "november", "desember"]

  money = []

  formatDate(d:Date){

    return this.days[d.getDay()] + " " + d.getDate() + ". " + this.monts[d.getMonth()];

  }

  icon(combo:Combined){
    if(!combo.gameNr){
      return "mdi-sleep"
    }
    if(combo.upcomingGame && combo.upcomingGame.result){
      if(combo.upcomingGame.result === this.DRAW){
        return "mdi-draw"
      }
      return "mdi-chess-king"
    }
  }

  iconColor(combo:Combined){
    if(!combo.gameNr){
      return ""
    }

    if(!combo.upcomingGame?.result){
      return "black"
    }
    if(combo.upcomingGame?.result === this.WHITE_WIN){
      return "white"
    }
    if(combo.upcomingGame?.result === this.BLACK_WIN){
      return "black"
    }
    if(combo.upcomingGame?.result === this.DRAW){
      return "grey"
    }

  }

  color(combo:Combined){
    if(!combo.gameNr){
      return "grey"
    }
    if(!combo.upcomingGame?.result){
      return "grey"
    }
    if(combo.upcomingGame?.result === this.WHITE_WIN){
      return "brown lighten-2"
    }
    if(combo.upcomingGame?.result === this.BLACK_WIN){
      return "brown lighten-4"
    }
    if(combo.upcomingGame?.result === this.DRAW){
      return "light-green lighten-3"
    }
  }

  colorFromGame(game: GameDto) {
    if(game.result === this.WHITE_WIN){
      return "white"
    }

    if(game.result === this.BLACK_WIN){
      return "black"
    }

    return "yellow"

  }
  get playerAWins() {
    return this.activeResult.playerA.win.white.length + this.activeResult.playerA.win.black.length
  }

  get playerBWins() {
    return this.activeResult.playerB.win.white.length + this.activeResult.playerB.win.black.length
  }

  get draws() {
    return this.activeResult.playerA.draw.white.length + this.activeResult.playerA.draw.black.length
  }

  updateCombined(){
    if(!this.chessBet){
      return;
    }

    let allDates: Date[] = []
    let adding = true;
    let firstDate = new Date(this.chessBet.upcomingGames[0].date);
    let lastDate = new Date(this.chessBet.upcomingGames[this.chessBet.upcomingGames.length-1].date);
    let activeDate = firstDate;

    while(adding) {

      if(activeDate > lastDate){
        break;
      }

      allDates.push(new Date(activeDate))
      activeDate.setDate(activeDate.getDate() + 1)
    }

    let gameNr = 0;
    this.combined = allDates.map(theDate => {
      let found = this.chessBet?.upcomingGames.find(g=> new Date(g.date).getTime() === theDate.getTime())
      if(found){
        gameNr++;
        return {date: new Date(found.date), upcomingGame: found, gameNr: gameNr}
      } else {
        return {date: theDate}
      }
    })

  }

  activeResult: ActiveResult = {
    playerA: {
      win: {
        white: [],
        black: []
      },
      lose: {
        white: [],
        black: []
      },
      draw: {
        white: [],
        black: []
      }
    },
    playerB: {
      win: {
        white: [],
        black: []
      },
      lose: {
        white: [],
        black: []
      },
      draw: {
        white: [],
        black: []
      }
    }
  }


  updateBets(){

    if(!this.chessBet){
      return;
    }

    let gamesLeft = this.chessBet.upcomingGames.length - this.chessBet.upcomingGames.filter(a=>!!a.result).length;

    this.stillValidBets = []
    this.betsPast = []
    this.betsNeverReach = []


    this.chessBet?.finalBets.forEach((bet, index) => {
      if(bet.playerA < this.playerAWins || bet.playerB < this.playerBWins){
        this.betsPast.push(bet)
      } else if((bet.playerA + bet.playerB) > (this.playerAWins + gamesLeft + this.playerBWins)){
        this.betsNeverReach.push(bet)
      } else {
        this.stillValidBets.push(bet)
      }
    })
  }

  get who() {
    if (!this.chessBet) {
      return null;
    }
    if (this.chessBet.betterA.exposedBetterId === this.chessBet.exposedBetterId) {
      return this.chessBet.betterA;
    }

    if (this.chessBet.betterB.exposedBetterId === this.chessBet.exposedBetterId) {
      return this.chessBet.betterB;
    }
    return null;
  }

  @Watch("who")
  amInext() {
    if (!this.who) {
      return false;
    }
    return this.who.exposedBetterId === this.nextBetterId;
  }

  get gameId() {
    return this.$route.params.gameId;
  }

  get betterId() {
    return this.$route.params.betterId;
  }

  resultString(result: string){
    if(result === this.WHITE_WIN){
      return "1-0"
    }
    if(result === this.BLACK_WIN){
      return "0-1"
    }
    if(result === this.DRAW){
      return "0.5-0.5"
    }

  }

  updateActiveResult() {
    if (!this.chessBet) {
      return;
    }

    this.chessBet.upcomingGames.forEach((g, i) => {
      let gameNr = i + 1
      if (g.result === this.WHITE_WIN) {
        if (g.white === this.chessBet?.playerA) {
          this.activeResult.playerA.win.white.push(gameNr)
          this.activeResult.playerB.lose.black.push(gameNr)
        } else if (g.white === this.chessBet?.playerB) {
          this.activeResult.playerB.win.white.push(gameNr)
          this.activeResult.playerA.lose.black.push(gameNr)
        }
      } else if (g.result === this.BLACK_WIN) {
        if (g.black === this.chessBet?.playerA) {
          this.activeResult.playerA.win.black.push(gameNr)
          this.activeResult.playerB.lose.white.push(gameNr)
        } else if (g.black === this.chessBet?.playerB) {
          this.activeResult.playerB.win.black.push(gameNr)
          this.activeResult.playerA.lose.white.push(gameNr)
        }

      } else if (g.result === this.DRAW) {
        if (g.black === this.chessBet?.playerA) {
          this.activeResult.playerA.draw.black.push(gameNr)
          this.activeResult.playerB.draw.white.push(gameNr)
        } else if (g.black === this.chessBet?.playerB) {
          this.activeResult.playerB.draw.black.push(gameNr)
          this.activeResult.playerA.draw.white.push(gameNr)
        }

      }

    })

  }

  get betterABets() {
    if (!this.chessBet) {
      return;
    }

    return this.chessBet.finalBets.filter(a => a.exposedBetterId === this.chessBet?.betterA.exposedBetterId)
  }

  get betterBBets() {
    if (!this.chessBet) {
      return;
    }

    return this.chessBet.finalBets.filter(a => a.exposedBetterId === this.chessBet?.betterB.exposedBetterId)
  }

  created() {
    this.getFullObject();

    this.getPossibleGames();
  }

  getFullObject() {

    this.error = null;

    getAll(this.gameId, this.betterId)
        .then((res) => {
          this.chessBet = res;
          this.updateActiveResult()
          this.updateBets()
          this.updateCombined()
        })
        .catch((e) => {
          this.error = e;
        })

  }


  getPossibleGames() {

    this.error = null;

    getAllPossibleGames(this.gameId, this.betterId)
        .then((res) => {
          this.possibleGames = res;
        })
        .catch((e) => {
          this.error = e;
        })

  }


};


