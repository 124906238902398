



















import {Component, Vue} from 'vue-property-decorator'
import {createBet} from "@/api";
import {CreatedResponse} from "@/types";


@Component({ components: { } })
export default class ChessBet extends Vue {

  createdResponse: CreatedResponse | null = null;
  error: string | null = null

  numberOfGames = 14;
  champion = "Carlsen";
  candidate = "Nepomniachtchi";

  get apiKey(){

    return this.$route.query.apikey && this.$route.query.apikey.toString();

  }


  creat(){

    this.error = null;

    createBet("FIDE Chess World Championship 2021", "Holm", "Methi", "Carlsen", "Nepomniachtchi", 14, "Carlsen", this.apiKey)
        .then((res) => {
          this.createdResponse = res;
        })
        .catch((e) => {
          this.error = e;
        })

  }

};


