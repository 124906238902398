

























































import {Component, Vue, Watch} from 'vue-property-decorator'
import {getAll, getBetsLeft, getNextBetter, placeBet} from "@/api";
import {BetDto, ChessBetDto} from "@/types";
import ChessBetLeft from "@/views/ChessBetLeft.vue";
import ChessBetChosen from "@/views/ChessBetChosen.vue";


@Component({components: {ChessBetChosen, ChessBetLeft}})
export default class Chess extends Vue {

  chessBet: ChessBetDto | null = null;
  betsLeft: BetDto[] = [];

  nextBetterId:number | null = null;

  error: string | null = null;

  intervalRef: number | null = null;

  betting: boolean = false;

  get who() {
    if (!this.chessBet) {
      return null;
    }
    if (this.chessBet.betterA.exposedBetterId === this.chessBet.exposedBetterId) {
      return this.chessBet.betterA;
    }

    if (this.chessBet.betterB.exposedBetterId === this.chessBet.exposedBetterId) {
      return this.chessBet.betterB;
    }
    return null;
  }

  @Watch("who")
  amInext(){
    if(!this.who){
      return false;
    }
      return this.who.exposedBetterId === this.nextBetterId;
  }

  get gameId() {
    return this.$route.params.gameId;
  }

  get betterId() {
    return this.$route.params.betterId;
  }

  get betterABets() {
    if (!this.chessBet) {
      return;
    }

    return this.chessBet.finalBets.filter(a => a.exposedBetterId === this.chessBet?.betterA.exposedBetterId)
  }

  get betterBBets() {
    if (!this.chessBet) {
      return;
    }

    return this.chessBet.finalBets.filter(a => a.exposedBetterId === this.chessBet?.betterB.exposedBetterId)
  }

  created() {
    this.getFullObject();

    this.fetchBetsLeft();
    this.next();

  }

  choose(bet: BetDto){

    this.betting = true
    this.error = null;

    placeBet(this.gameId, this.betterId, bet)
        .then((res) => {
          this.getFullObject();
          this.fetchBetsLeft();
          this.next();
          this.betting = false;
        })
        .catch((e) => {
          this.error = e;
          this.betting = false;
        })

  }

  checkNext(){

    this.error = null;

    getNextBetter(this.gameId, this.betterId)
        .then((res) => {
          if(res !== this.nextBetterId){
            this.nextBetterId = res;
            this.getFullObject();
            this.fetchBetsLeft();
          }

          if(res === 0 && this.intervalRef){
            clearInterval(this.intervalRef)
          }

        })
        .catch((e) => {
          this.error = e;
        })

  }

  next(){

    this.error = null;

    getNextBetter(this.gameId, this.betterId)
        .then((res) => {
          this.nextBetterId = res;

          if(!this.intervalRef){
            this.intervalRef = setInterval(this.checkNext, 3000)
          }
        })
        .catch((e) => {
          this.error = e;
        })

  }

  getFullObject() {

    this.error = null;

    getAll(this.gameId, this.betterId)
        .then((res) => {
          this.chessBet = res;
        })
        .catch((e) => {
          this.error = e;
        })

  }


  fetchBetsLeft() {

    this.error = null;

    getBetsLeft(this.gameId, this.betterId)
        .then((res) => {
          this.betsLeft = res;
        })
        .catch((e) => {
          this.error = e;
        })

  }

};


