import Vue from 'vue';
import VueRouter from 'vue-router';
import ChessBet from '../views/ChessBet.vue';
import Chess from '../views/Chess.vue';
import Live from '../views/Live.vue';
import NotFound from '../views/NotFound.vue';

Vue.use(VueRouter);

const routes = [
 {
    path: '/chessbet',
    name: 'chessbet',
    component: ChessBet,
    props: true,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/chess/:gameId/:betterId',
    name: 'chess',
    component: Chess,
    props: true,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/live/:gameId/:betterId',
    name: 'live',
    component: Live,
    props: true,
    meta: {
      requiresAuth: true
    },
  },
  { path: '*', component: NotFound }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  next()
});


export default router;
