



















































import {Component, Prop, Vue} from 'vue-property-decorator'
import {BetDto, BetterDto, FinalBetDto} from "@/types";

@Component({components: {}})
export default class LiveBets extends Vue {

  @Prop({required: true}) stillValidBets!: FinalBetDto[];
  @Prop({required: true}) betsPast!: FinalBetDto[];
  @Prop({required: true}) betsNeverReach!: FinalBetDto[];
  @Prop({required: true}) better!: BetterDto;
  @Prop({required: true}) playerA!: string;
  @Prop({required: true}) playerB!: string;
  @Prop({required: true}) playerAwins!: number;
  @Prop({required: true}) playerBwins!: number;


  get stillValidBetsFiltered() {
    return this.stillValidBets.filter(a => a.exposedBetterId === this.better.exposedBetterId)
  }

  get betsPastFiltered() {
    return this.betsPast.filter(a => a.exposedBetterId === this.better.exposedBetterId)
  }

  get betsNeverReachFiltered() {
    return this.betsNeverReach.filter(a => a.exposedBetterId === this.better.exposedBetterId)
  }

  classs(bet: FinalBetDto){
    if(bet.playerA === this.playerAwins && bet.playerB === this.playerBwins){
      return "yellow lighten-5"
    }
    return "teal lighten-5"
  }

};


